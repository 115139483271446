import {PracticeDetail} from "../../../models/Practice/PracticeDetail";
import {MiddleColumnStatus} from "../../../pages/PracticesDetails/PracticesDetails";
import {DetailPart} from "./components/DetailPart";
import {ChatColumn} from "../ChatColumn/ChatColumn";
import React from "react";
import {NoteColumn} from "../NoteColumn/NoteColumn";
import {PracticeFileRow} from "../../../models/Practice/PracticeFIle";

export interface MiddleColumnProps {
    status: MiddleColumnStatus;
    practiceDetail: PracticeDetail;
    attachedFiles: Array<PracticeFileRow>;
    changeVisus?: string,
    onClickSavePurchase: () => void;
    onClickConfirmDocs: () => void;
    onClickExit: () => void;
    onSaveMessage: () => void;
    onSaveFile: () => void;
    reloadDetail: (showNoteWarning?: boolean) => Promise<void>
    handleMiddleColumnSpin:(value:boolean)=>void;
}

export function MiddleColumn(props: MiddleColumnProps) {
    if (props.status === MiddleColumnStatus.INFO) {
        return <DetailPart practiceDetail={props.practiceDetail}
                           changeVisus={props.changeVisus}
                           onClickSavePurchase={props.onClickSavePurchase}
                           onSaveFile={props.onSaveFile}
                           attachedFiles={props.attachedFiles}
                           onClickConfirmDocs={props.onClickConfirmDocs}
                           reloadDetail={props.reloadDetail}
                           handleMiddleColumnSpin={(value:boolean) => props.handleMiddleColumnSpin(value)}
        />
    } else if (props.status === MiddleColumnStatus.CHAT) {
        return <ChatColumn onClickExit={props.onClickExit}
                           onSaveMessage={props.onSaveMessage}
                           claimStatus={props.practiceDetail.claimStatus}
                           progress={props.practiceDetail.progress}/>
    } else if (props.status === MiddleColumnStatus.NOTE) {
        return <NoteColumn onClickExit={props.onClickExit} practiceDetail={props.practiceDetail} reloadDetail={props.reloadDetail} />
    } else {
        return <></>
    }
}
