import {isNil, toNumber, uniqueId} from "lodash";
import dayjs from "dayjs";
import {PracticeProgressStatus} from "../models/Practice/Practice";
import {PracticeProgress} from "../models/Practice/PracticeDetail";

export class Utils{
    static IT_DATE_FORMAT = "DD/MM/YYYY";
    static IT_DATETIME_FORMAT = "DD/MM/YYYY HH:mm";
    static DATE_ISO_8601="YYYY-MM-DD"
    static addReactUniqueKey(listOfValues:any[], propertyKeyName:string="key"){
        return listOfValues.map((value:any)=>{
            value[propertyKeyName] = uniqueId();
            return value
        })
    }
    static replaceId(url:string,id:string|undefined){
        let returnUrl = url;
        if (id !== undefined) {
            returnUrl = url.replace(":id", id);
        }
        return returnUrl;
    }
    static getDateItFormat(date:string){
        return dayjs(date).format(this.IT_DATE_FORMAT);
    }
    static getProgressData(status:PracticeProgressStatus,progress:PracticeProgress[]|undefined): PracticeProgress|undefined{
        if (isNil(progress)) return undefined;

        const sortedProgress = progress.sort((a, b) => b.completedAt - a.completedAt);
        return sortedProgress.find((element: PracticeProgress) => {
            return element.name.toLowerCase() === status.toLowerCase();
        });
    }
    static getLastProgressData(progress:PracticeProgress[]|undefined): PracticeProgress|undefined{
        if (isNil(progress)) return undefined;
        const sortedProgress = progress.sort((a, b) => {
            if (b.completedAt !== a.completedAt) {
                return b.completedAt - a.completedAt;
            }
            return b.id - a.id;
        });
        return sortedProgress.length > 0 ? sortedProgress[0] : undefined;
    }
    static isStatusInProgress(status:PracticeProgressStatus,progress:PracticeProgress[]|undefined): boolean {
        const p = this.getProgressData(status, progress);
        return isNil(p) === false;
    }

    static getDateItFormatFromTimestamp(timestamp:number|string){
        const date = dayjs(toNumber(timestamp));
        return date.format(this.IT_DATE_FORMAT);
    }
    static getDatetimeItFormatFromTimestamp(timestamp:number|string) {
        const date = dayjs(toNumber(timestamp));
        return date.format(this.IT_DATETIME_FORMAT);
    }
    static capitalizeFirstLetter(value:string) {
        if (!value) return '';

        return value.split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    }

}
