import {TableLuxottica} from "../../../../Common/TableLuxottica/TableLuxottica";
import {Flex, TableProps} from "antd";
import {IconButton} from "../../../../Common/IconButton/IconButton";
import {IconLuxotticaDimension} from "../../../../../models/IconLuxotticaDimension";
import {CloudDownloadOutlined, DeleteFilled} from "@ant-design/icons";
import {AttachmentType, PracticeFileRow} from "../../../../../models/Practice/PracticeFIle";
import {Utils} from "../../../../../services/Utils";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import {PracticeProgressStatus} from "../../../../../models/Practice/Practice";
import {ClaimStatuses, PracticeProgress} from "../../../../../models/Practice/PracticeDetail";

export interface LoadedFilesTableProps {
    files: Array<PracticeFileRow>;
    deleteFileCallback:(attachmentId:number)=>void;
    downloadFileCallback:(attachmentId:number,filename:string)=>void;
    progress:PracticeProgress[];
    state: PracticeProgressStatus;
    claimStatus: ClaimStatuses;
}

export function LoadedFilesTable(props: LoadedFilesTableProps) {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const columns :TableProps<any>['columns']=[
        {
            title: 'Tipo documento',
            dataIndex: 'type',
            key: 'type',
            render:(value:string)=>{
                return <>{
                    t(value)
                }</>
            }
        }, {
            title: 'Nome documento',
            dataIndex: 'filename',
            key: 'filename',
        }, {
            title: 'Data inserimento',
            dataIndex: 'insertDate',
            key: 'insertDate',
            render:(date:string)=>{
                return <>{
                    Utils.getDateItFormat(date)
                }</>
            }
        }, {
            title: '',
            render: (record:PracticeFileRow) => {
                let canDelete = true;
                if ([AttachmentType.ESTIMATE, AttachmentType.CAMBIO_VISUS, AttachmentType.VOUCHER].includes(record.type)) {
                    canDelete = [
                        PracticeProgressStatus.DA_CONTATTARE,
                        PracticeProgressStatus.CONTATTATO,
                        PracticeProgressStatus.PRESO_APPUNTAMENTO,
                        PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE
                    ].includes(props.state)
                } else if ([AttachmentType.PIC].includes(record.type)) {
                    canDelete = false
                } else if ([AttachmentType.INVOICE, AttachmentType.DRAFT_INVOICE, AttachmentType.DECLARATION, AttachmentType.SIGNED_PIC, AttachmentType.LENS_COMPLAINCE].includes(record.type)) {
                    canDelete = [
                        PracticeProgressStatus.PIC_AUTORIZZATA,
                        PracticeProgressStatus.ERRORE_IN_FATTURA
                    ].includes(props.state)
                }

                if (props.claimStatus === ClaimStatuses.CLOSED) {
                    canDelete = false;
                }

                return <>
                    <Flex gap={15}>
                        <IconButton icon={<CloudDownloadOutlined />}
                                    dimension={IconLuxotticaDimension.MEDIUM}
                                    onCLick={ ()=>{props.downloadFileCallback(record.code,record.filename)} }></IconButton>
                        { canDelete && <IconButton icon={<DeleteFilled />}
                                                    dimension={IconLuxotticaDimension.MEDIUM}
                                                    onCLick={ ()=>{props.deleteFileCallback(record.code)} }></IconButton> }
                    </Flex>
                </>
            }
        }
    ]

    function getScrollY(){
        if(isTabletOrMobile) {
            return 100
        }
        return 180
    }

    return <div>
        <TableLuxottica virtualScroll={false} columns={columns} data={props.files} scrollY={getScrollY()}></TableLuxottica>
    </div>
}