import {AuthBody} from "../models/Auth/AuthBody";
import {HttpService} from "./Http/HttpService";
import {AppUser} from "../models/Auth/AppUser";
import {isNil} from "lodash";
import {UserHttpService} from "./Http/UserHttpService";
import { signOut } from "aws-amplify/auth";
import {LogService} from "./LogService";
import {Utils} from "./Utils";
import {AxiosResponse} from "axios";
import {Employee} from "../models/Auth/Employee";

export class AuthService {
    static USER_KEY_SESSION = "user";
    static USER_ACCESS_TOKEN = 'eclaim_access_token';
    static EMPLOYEES="/operators";
    static EMPLOYEES_SEARCH="/operators/search";
    static OPERATOR_KEY="operator"
    static ADMIN_ROLE= "ROLE_AMMINISTRATORE";
    static ADMIN_LUX_ROLE = "ROLE_ADMIN_LUX";
    static AREA_MANAGER_ROLE = "ROLE_AREA_MANAGER";

    static async login(body: AuthBody) {
        const response = await HttpService.getLuxotticaAxios().post("/login", body);
        const {data, headers} = response;
        const appUser = data.appUser;
        const token = headers.authorization.replace('Bearer ', '');
        localStorage.setItem(this.USER_ACCESS_TOKEN, token);
        localStorage.setItem(this.USER_KEY_SESSION, JSON.stringify(appUser));
        await UserHttpService.getUserProfile();
    }

    static getUserTokens() {
        return localStorage.getItem(this.USER_ACCESS_TOKEN);
    }

    static getUser(): AppUser | null {
        let valueFromSession: string | null = localStorage.getItem(this.USER_KEY_SESSION);
        if (isNil(valueFromSession)) {
            return null;
        }

        let returnValue: AppUser | null = null;
        try {
            returnValue = JSON.parse(valueFromSession);
        } catch (_) {
            LogService.error("cannot parse the appUser from sessionStorage");
            localStorage.removeItem(this.USER_KEY_SESSION);
        }
        
        return returnValue
    }

    static isAdmin(){
        const user: AppUser | null = this.getUser();
        if (user == null) return false;

        return user.authorities.includes(this.ADMIN_ROLE);
    }

    static isAdminLux() {
        const user: AppUser | null = this.getUser();
        if (user == null) return false;

        return user.authorities.includes(this.ADMIN_LUX_ROLE);
    }

    static isAreaManager() {
        const user: AppUser | null = this.getUser();
        if (user == null) return false;

        return this.isAdmin() || this.isAdminLux() || user.authorities.includes(this.AREA_MANAGER_ROLE);
    }

    static clearSession() {
        localStorage.clear();
    }

    static async logout() {
        await signOut();
        this.clearSession();
    }

    static getOperators (id:string):Promise<AxiosResponse<Employee[],any>>{
        let url = Utils.replaceId(this.EMPLOYEES ,id);
        return HttpService.getLuxotticaAxios().get(url);
    }
    static searchOperators(query:string):Promise<AxiosResponse<Employee[],any>>{
        return HttpService.getLuxotticaAxios().get(this.EMPLOYEES_SEARCH+ `?query=${query}`);
    }
    static saveOperator (operator:Employee|undefined){
        if(isNil(operator) === false){
            localStorage.setItem(this.OPERATOR_KEY, JSON.stringify(operator));
        }
    }
    static getOperatorOnline():Employee|null{
        let valueFromSession: string | null = localStorage.getItem(this.OPERATOR_KEY);
        if (isNil(valueFromSession)) {
            return null;
        }
        let returnValue: Employee | null = null;
        try {
            returnValue = JSON.parse(valueFromSession);
        } catch (_) {
            LogService.error("cannot parse the appUser from sessionStorage");
            localStorage.removeItem(this.OPERATOR_KEY);
        }

        return returnValue
    }

    static canUseScanner(): boolean {
        const user = AuthService.getUser();
        return user != null;
    }

}
