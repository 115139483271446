import {HttpService} from "./HttpService";
import {AxiosResponse} from "axios";
import {PracticeFilesResponse} from "../../models/Practice/PracticeFIle";
import {
    ClaimProgressInfoResponse,
    ClosingPracticeBody,
    PracticeDetail,
    ProceedNextRequest, ShelveCambioVisusBodyRequest, ShelveEstimateBodyRequest, UploadEstimateBodyRequest
} from "../../models/Practice/PracticeDetail";
import {QueryParameterModel} from "../../models/QueryParameter";
import {QueryParamsService} from "./QueryParamsService";
import {PracticeResponse, PracticeSearchBody} from "../../models/Practice/Practice";
import {PracticeNotePutBody, PracticeNoteResponse} from "../../models/Practice/PracticeNote";
import { PracticeMessageBody, PracticeMessageResponse} from "../../models/Practice/PracticeMessage";
import {TodoListFilter, TodoListStats} from "../../models/TodoList/TodoListStats";
import {Utils} from "../Utils";
import {UploadCambioVisusRequest} from "../../models/cambioVisus";
import {PracticeToSave} from "../../models/Practice/PracticeToSave";
import {SelectionValue} from "../../models/Common/SelectionValue";
import {ShelveRefundModuleBodyRequest} from "../../models/refundModule";
import {PracticeTotalResponse} from "../../models/Practice/PracticeTotals";

export class PracticesHttpService{
    static URL_PRACTICES="/claims"
    static URL_PRACTICES_SEARCH="/claims/search"
    static URL_PRACTICES_FILES="/claims/:id/attachments"
    static URL_PRACTICES_FILE="/claims/:id/attachment"
    static URL_PRACTICES_CLOSE="/claims/:id/close"
    static URL_PRACTICES_NOTE="/claims/:id/notes"
    static URL_PRACTICES_TOTALS="/claims/:id/totals"
    static URL_PRACTICES_CONTACTS="/claims/:id/contacts"
    static URL_PRACTICES_TODOLIST_STATS="/claims/todoListStats"
    static URL_PRACTICES_CAMBIOVISUS="/claims/:id/cambioVisus"
    static URL_PRACTICES_PROCEED_NEXT="/claims/:id/proceedNext"
    static URL_GENERALI_PROCEED_NEXT="/claims/:id/generaliRerun"
    static URL_PRACTICES_FORCE_PROCEED_NEXT="/claims/:id/forceProceedNext"
    static URL_GET_CLAIM_PROGRESS = "/claims/:id/progressInfo"
    static URL_UPLOAD_ESTIMATE = "/claims/:id/uploadEstimate"
    static URL_GET_SERVICES_BY_CLAIM_ID = "/claims/:id/services"
    static URL_GET_SERVICES_BY_GENERIC_INFO = "/claims/services"
    static URL_TRANSFORM_TO_INDIRECT = "/claims/:id/transformToIndirect"
    static URL_SHELVE_ESTIMATE = "/claims/shelveEstimate"
    static URL_SHELVE_CAMBIO_VISUS = "/claims/shelveCambiovisus"
    static URL_SHELVE_REFUND_MODULE = "/claims/shelveRefundModule"
    static URL_FORCE_DELETE_PIC = "/claims/:id/forceDeletePic"
    static URL_REOPEN_BY_CLAIM_ID = "/claims/:id/reopen"

    static getListOfPractices(queryParameters:QueryParameterModel[],bodyFilters:PracticeSearchBody|undefined):Promise<AxiosResponse<PracticeResponse,any>>{
        let url:string = QueryParamsService.getUrl(queryParameters);
        return HttpService.getLuxotticaAxios().post<PracticeResponse>(this.URL_PRACTICES_SEARCH+url,bodyFilters);
    }
    static getPracticeDetail(key:string|undefined):Promise<AxiosResponse<PracticeDetail,any>>{
        return HttpService.getLuxotticaAxios().get(this.URL_PRACTICES + "/" + key);
    }

    static getPracticeFiles (key:string|undefined):Promise<AxiosResponse<PracticeFilesResponse,any>>{
        let url = Utils.replaceId(this.URL_PRACTICES_FILES,key);
        return HttpService.getLuxotticaAxios().get<PracticeFilesResponse>(url);
    }
    static deletePracticeFile(idClaim:string|undefined,attachmentId:number){
        let url = Utils.replaceId(this.URL_PRACTICES_FILES,idClaim);
        return HttpService.getLuxotticaAxios().delete<PracticeFilesResponse>(url + "/" + attachmentId);

    }
    static downloadPracticeFile (idClaim:string|undefined,attachmentId:number):Promise<AxiosResponse<any,any>>{
        let url = Utils.replaceId(this.URL_PRACTICES_FILE,idClaim);
        return HttpService.getLuxotticaAxios().get<any>(url + "/" + attachmentId,{responseType: 'blob'});
    }
    static async uploadPracticeFiles  (id:string|undefined,formData:FormData ):Promise<AxiosResponse<any, any>>{
        let url = Utils.replaceId(this.URL_PRACTICES_FILE,id);
        return await HttpService.getLuxotticaAxios().put<any>(url, formData)
    }

    static closePractice(body:ClosingPracticeBody,key:string){
        let url = Utils.replaceId(this.URL_PRACTICES_CLOSE,key);
        return HttpService.getLuxotticaAxios().post(url,body)
    }
    static getPracticeNotes(key:string|undefined):Promise<AxiosResponse<PracticeNoteResponse,any>>{
        let url = Utils.replaceId(this.URL_PRACTICES_NOTE,key);
        return HttpService.getLuxotticaAxios().get(url)
    }

    static getClaimTotals(key:string|undefined):Promise<AxiosResponse<PracticeTotalResponse,any>>{
        let url = Utils.replaceId(this.URL_PRACTICES_TOTALS,key);
        return HttpService.getLuxotticaAxios().get(url)
    }

    static forceDeletePic(key:string){
        let url = Utils.replaceId(this.URL_FORCE_DELETE_PIC,key);
        return HttpService.getLuxotticaAxios().delete(url)
    }

    static forceReopen(key:string){
        let url = Utils.replaceId(this.URL_REOPEN_BY_CLAIM_ID,key);
        return HttpService.getLuxotticaAxios().put(url)
    }

    static savePracticeNotes(body:PracticeNotePutBody, key:string){
        let url = Utils.replaceId(this.URL_PRACTICES_NOTE,key);
        return HttpService.getLuxotticaAxios().put(url,body);
    }
    static getPracticeMessages(key:string|undefined):Promise<AxiosResponse<PracticeMessageResponse,any>>{
        let url = Utils.replaceId(this.URL_PRACTICES_CONTACTS,key);
        return HttpService.getLuxotticaAxios().get(url)
    }
    static savePracticeMessage(body:PracticeMessageBody,key:string|undefined ){
        let url = Utils.replaceId(this.URL_PRACTICES_CONTACTS,key);
        return HttpService.getLuxotticaAxios().put(url,body);
    }
    static getTodoListStats (body: TodoListFilter):Promise<AxiosResponse<TodoListStats,any>>{
        return HttpService.getLuxotticaAxios().post(this.URL_PRACTICES_TODOLIST_STATS, body);
    }

    static saveClaimChangeVisus(body:UploadCambioVisusRequest, key:string|undefined){
        let url = Utils.replaceId(this.URL_PRACTICES_CAMBIOVISUS,key);
        return HttpService.getLuxotticaAxios().post(url,body);
    }

    static savePractice(body:PracticeToSave): Promise<AxiosResponse<number>> {
        return HttpService.getLuxotticaAxios().put(this.URL_PRACTICES +"/",body);
    }

    static saveProceedNext(id:string, body:ProceedNextRequest){
        let url = Utils.replaceId(this.URL_PRACTICES_PROCEED_NEXT,id);
        return HttpService.getLuxotticaAxios().post(url,body);
    }

    static saveForceProceedNext(id:string, body:ProceedNextRequest){
        let url = Utils.replaceId(this.URL_PRACTICES_FORCE_PROCEED_NEXT,id);
        return HttpService.getLuxotticaAxios().post(url,body);
    }

    static rerunGeneraliProcedure(id:string){
        let url = Utils.replaceId(this.URL_GENERALI_PROCEED_NEXT,id);
        return HttpService.getLuxotticaAxios().post(url, {});
    }

    static getProgressInfo(id:number):Promise<AxiosResponse<ClaimProgressInfoResponse,any>>{
        let url = Utils.replaceId(this.URL_GET_CLAIM_PROGRESS, id.toString());
        return HttpService.getLuxotticaAxios().get(url);
    }

    static uploadEstimate(id:string,body:UploadEstimateBodyRequest):Promise<AxiosResponse<any,any>>{
        let url = Utils.replaceId(this.URL_UPLOAD_ESTIMATE, id);
        return HttpService.getLuxotticaAxios().post(url,body);
    }
    static getServicesByClaimId(id:string):Promise<AxiosResponse<Array<SelectionValue>,any>>{
        let url = Utils.replaceId(this.URL_GET_SERVICES_BY_CLAIM_ID, id);
        return HttpService.getLuxotticaAxios().get(url);
    }
    static getServicesByGenericInfo(insuranceProviderId: number, locationCode: string): Promise<AxiosResponse<Array<SelectionValue>>> {
        return HttpService.getLuxotticaAxios().get(this.URL_GET_SERVICES_BY_GENERIC_INFO, {
            params: {
                insuranceProviderId,
                locationCode
            }
        });
    }
    static transformToIndirect(id:string) {
        let url = Utils.replaceId(this.URL_TRANSFORM_TO_INDIRECT, id);
        return HttpService.getLuxotticaAxios().get(url)
    }
    static shelveEstimate(body: ShelveEstimateBodyRequest): Promise<AxiosResponse<any>> {
        return HttpService.getLuxotticaAxios().post(this.URL_SHELVE_ESTIMATE, body, {responseType: 'blob'});
    }
    static shelveCambiovisus(body: ShelveCambioVisusBodyRequest): Promise<AxiosResponse<any>> {
        return HttpService.getLuxotticaAxios().post(this.URL_SHELVE_CAMBIO_VISUS, body, {responseType: 'blob'})
    }
    static shelveRefundModule(body: ShelveRefundModuleBodyRequest): Promise<AxiosResponse<any>> {
        return HttpService.getLuxotticaAxios().post(this.URL_SHELVE_REFUND_MODULE, body, {responseType: 'blob'})
    }
}
