import {Row} from "antd";
import React, {useEffect, useState} from "react";
import {ContractTotals} from "../../models/Practice/PracticeTotals";
import {PracticeType} from "../../models/Practice/Practice";

export interface GeneraliTotalsInfoProps{
    data?: ContractTotals[] | undefined
}
export function GeneraliTotalsInfo(props:GeneraliTotalsInfoProps)  {
    const [patientAmount,setPatientmount]   = useState<number>(0);
    const [generaliAmount,setGeneraliAmount]   = useState<number>(0);
    useEffect(() => {
        loadData();
    }, []);
    const loadData = async () => {
        props.data?.filter((item:ContractTotals) => {
            return item.contract.refundType === PracticeType.DIRECT;
        }).forEach((item:ContractTotals) => {
            setPatientmount(item.patient.overall);
            setGeneraliAmount(item.insurances[0].overall);
        })
    };
    if (!props.data) {
        return <>
            <Row gutter={16}>
                Totali non disponibili
            </Row>
        </>
    }
    return <>
        <Row gutter={16}>
            Di seguito le quote ripartite tra Ente e Cliente
        </Row>
        <br />
        <Row gutter={16}>
            <strong>Generali</strong>: {Number(generaliAmount).toFixed(2)} euro
        </Row>
        <Row gutter={16}>
            <strong>Cliente</strong>: {Number(patientAmount).toFixed(2)} euro
        </Row>
        <Row gutter={16}>
            <br/>
            <span><strong>Importante</strong>: in cassa Ciao! imputare quota a carico dell’assicurazione sul tasto corretto.</span>
            <span><strong>Attenzione</strong>: la quota Caparra deve essere sempre uguale o inferiore alla quota a carico dell’assistito.</span>
        </Row>
    </>
}
