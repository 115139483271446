import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Paths} from "../../models/Paths";
import {Button, Card, Col, Flex, Form, Modal, Row} from "antd";
import {DataFromField} from "../../components/DashBoard/Fields/DataFromField";
import {DataToField} from "../../components/DashBoard/Fields/DataToField";
import {AreaField} from "../../components/DashBoard/Fields/AreaField";
import {FilterShop} from "../../components/Common/PracticesTableFilters/filters/FilterShop";
import {FilterRefundType} from "../../components/Common/PracticesTableFilters/filters/FilterRefundType";
import {DashboardHttpService} from "../../services/Http/DashboardHttpService";
import {PieChart} from "../../components/DashBoard/PieChart/PieChart";
import "./DashBoardPage.css"
import {
    Dashboard,
    DashboardFilter,
    DashboardIdSeries,
    ExportDashboardDataRequest
} from "../../models/Dashboard/Dashboard";
import React, {useEffect, useState} from "react";
import {DashboardTable} from "../../components/DashBoard/DashboardTable/DashboardTable";
import {isNil, pick} from "lodash";
import {useTranslation} from "react-i18next";
import {AuthService} from "../../services/AuthService";
import {Navigate} from "react-router-dom";
import {FilterInsegna} from "../../components/Common/PracticesTableFilters/filters/FilterInsegna";
import {useDispatch, useSelector} from "react-redux";
import {saveDashboardFormFilters, saveDashboardSeries} from "../../store/states/DashboardFormFiltersState";
import {useAreas} from "../../utils/areas";
import {useShops} from "../../utils/shops";
import {PracticeType} from "../../models/Practice/Practice";
import {Dayjs} from "dayjs";
import {saveFile} from "../../utils/files";
import {NotificationService} from "../../services/NotificationService";
import {FilterStatus} from "../../components/Common/PracticesTableFilters/filters/FilterStatus";
import {ClaimStatuses} from "../../models/Practice/PracticeDetail";

export interface DashboardForm {
    areaField?: string;
    insegnaCode?: string;
    locationCode?: string;
    claimStatus?: ClaimStatuses;
    refundType?: PracticeType;
    dataFrom?: Dayjs;
    dataTo?: Dayjs;
}

export function DashBoardPage(){
    const [data,setData]= useState<Dashboard>()
    const [selectedIdSeries,setSelectedIdSeries] = useState<DashboardIdSeries|null>()
    const { t, i18n } = useTranslation();
    const[filters,setFilters]= useState<DashboardFilter>();
    const dispatch = useDispatch();
    const formValues = useSelector((state:any) => state.dashboardFormFilters.values);
    const seriesValues = useSelector((state:any) => state.dashboardFormFilters.series);
    const [form] = Form.useForm();

    const [modal, contextHolder] = Modal.useModal();
    const [isExporting, setExporting] = useState(false);

    const {areas, loadAreas} = useAreas();
    const {shops, loadShops} = useShops();

    useEffect(() => {
        loadAreas();
        loadShops();
    }, []);

    useEffect(() => {
        form.setFieldsValue(formValues);
    }, [form, formValues]);

    useEffect(() => {
        if(isNil(seriesValues) === false && isNil(seriesValues.data) === false){
            setFilters(formValues)
            loadData(formValues).then(()=>{
                selectIdSeries(seriesValues)
            })
        }
    }, [seriesValues]);

    const onFinish = async (values: DashboardFilter) => {
        dispatch(saveDashboardFormFilters(values));
        setFilters(values)
        await loadData(values);
    };

    const onReset = () => {
        setData(undefined);
    };

    const loadData = async (values: DashboardFilter) => {
        const {data} = await DashboardHttpService.getDashboardData(values);
        setData({
            inProgress: data.inLavorazione,
            purchaseMade: data.acquistoEffettuato,
            closedWithoutSale: data.chiuseSenzaVendita,
            toBeContacted: data.daContattare,
            expired: data.scadute,
            actives: data.daContattare + data.inLavorazione,
            finished: data.acquistoEffettuato
        });
    };

    const onClickSeries = (param:any) => {
        let valueToSave = pick(param,["data"])
        dispatch(saveDashboardSeries(valueToSave))
        selectIdSeries(param);

    };

    const selectIdSeries = (param:any) => {
        switch(param.data.id){
            case DashboardIdSeries.CLOSE_WITHOUT_SALE:
                setSelectedIdSeries(DashboardIdSeries.CLOSE_WITHOUT_SALE)
                break;
            case DashboardIdSeries.PURCHASE_MADE:
                setSelectedIdSeries(DashboardIdSeries.PURCHASE_MADE)
                break;
            case DashboardIdSeries.TO_BE_CONTACTED:
                setSelectedIdSeries(DashboardIdSeries.TO_BE_CONTACTED)
                break;
            case DashboardIdSeries.IN_PROGRESS:
                setSelectedIdSeries(DashboardIdSeries.IN_PROGRESS)
                break;
            case DashboardIdSeries.EXPIRED:
                setSelectedIdSeries(DashboardIdSeries.EXPIRED);
                break;
        }
    };

    const onClickBackButton = () => {
        setSelectedIdSeries(null)
    };

    const getTableTitle = () => {
        let returnValue = " "
        if(isNil(selectedIdSeries) === false && isNil(data) === false)
        {
            // @ts-ignore
            returnValue = t(selectedIdSeries);
            // @ts-ignore
            returnValue += " " + data[selectedIdSeries];
        }
        return returnValue
    };

    const onSignChange = () => {
        loadAreas(form.getFieldValue('insegnaCode'));
        form.resetFields(['areaField', 'locationCode']);
        loadShops();
    };

    const onAreaChange = () => {
        form.resetFields(['locationCode']);
        loadShops(form.getFieldValue('areaField'));
    };

    const exportData = async () => {
        const values: DashboardForm = form.getFieldsValue();

        const from = values.dataFrom;
        const to = values.dataTo;
        if (isNil(from) || isNil(to)) {
            modal.error({
                title: 'Errore!',
                content: 'Per fare un esportazione devi indicare una data!'
            })
            return;
        }

        if (from.isAfter(to)) {
            modal.error({
                title: 'Errore!',
                content: 'La data di inizio non può essere dopo la data di fine'
            })
            return;
        }

        const diffInMonths = to.diff(from, 'month');
        const diffInDays = to.diff(from, 'day');
        if (diffInMonths >= 3 && diffInDays > 90) {
            modal.error({
                title: 'Errore!',
                content: 'La differenza tra inizio e fine non può superare i 3 mesi'
            })
            return;
        }

        setExporting(true);

        const locationGroupCode: Array<string> = [];
        if (values.insegnaCode)
            locationGroupCode.push(values.insegnaCode)
        if (values.areaField)
            locationGroupCode.push(values.areaField)

        const body: ExportDashboardDataRequest = {
            locationCode: values.locationCode,
            refundType: values.refundType,
            creationDateFrom: from,
            creationDateTo: to,
            locationGroupCode: locationGroupCode,
            claimStatus: values.claimStatus
        }

        try {
            const response = await DashboardHttpService.exportDashboardData(body);
            saveFile(response.data, "export.xlsx");
        } catch (e) {
            NotificationService.getInstance().openNotificationError("Impossibile esportare i risultati!")
        } finally {
            setExporting(false);
        }
    }

    if (AuthService.isAreaManager()) {
        return <div className={"dashboad-page"}>
            <PageTitle title={"Dashboard"} backTo={Paths.HOME}></PageTitle>
            {contextHolder}
            <Form
                form={form}
                onFinish={onFinish}
                onReset={onReset}
                layout="inline"
                id="dashboardForm"
            >
                <Row>
                    <Col span={20}>
                        <Flex wrap={'wrap'} gap="small">
                            <DataFromField></DataFromField>
                            <DataToField></DataToField>
                            <FilterInsegna className={"dashboard-field"} onChange={onSignChange}></FilterInsegna>
                            <AreaField areas={areas} className={"dashboard-field"} onChange={onAreaChange}></AreaField>
                            <FilterShop shops={shops} className={"dashboard-field"}></FilterShop>
                            <FilterRefundType className={"dashboard-field"}></FilterRefundType>
                            <FilterStatus></FilterStatus>
                        </Flex>
                    </Col>
                    <Col span={4}>
                        <Flex vertical gap="small">
                            <Flex>
                                <Form.Item>
                                    <Button type={"default"} className={"button"} form="dashboardForm" key="reset"
                                            htmlType={"reset"}>Pulisci</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button className={"button button-submit"} type={"primary"} form="dashboardForm" key="submit"
                                            htmlType={"submit"}>Applica</Button>
                                </Form.Item>
                            </Flex>
                            <Form.Item>
                                <Button className={"button"} onClick={exportData} loading={isExporting}>Esporta</Button>
                            </Form.Item>
                        </Flex>
                    </Col>
                </Row>

            </Form>

            <Card className={"dashboard-card"}>

                {isNil(selectedIdSeries) === true && isNil(data) === false &&
                    <PieChart data={data} onCLickSeries={onClickSeries}></PieChart>}
                {isNil(selectedIdSeries) === false && isNil(data) === false &&
                    <DashboardTable dashboardIdSeries={selectedIdSeries}
                                    onClickBack={onClickBackButton}
                                    title={getTableTitle()}
                                    filters={filters}></DashboardTable>}
            </Card>
        </div>
    } else {
        return <Navigate to={Paths.HOME} replace />
    }
}